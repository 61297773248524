var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-left w-full", attrs: { id: "client-selector" } },
    [
      _c(
        "div",
        {
          staticClass: "relative",
          on: {
            keydown: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k(
                    $event.keyCode,
                    "escape",
                    undefined,
                    $event.key,
                    undefined
                  )
                )
                  return null
                return _vm.close.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onArrowDown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onArrowUp.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.onEnter.apply(null, arguments)
              },
            ],
          },
        },
        [
          _c("div", { staticClass: "rounded-md hover:shadow-sm" }, [
            _c(
              "button",
              {
                staticClass:
                  "inline-flex justify-between rounded-md rounded-l-none items-center w-full px-2 py-2 h-10 text-sm leading-5 font-medium text-white bg-gray-800 font-bold focus:outline-none focus:shadow-outline-gray transition ease-in-out duration-150",
                attrs: { type: "button" },
                on: { click: _vm.onButtonClick },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "flex justify-between items-center w-full cursor-pointer",
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "block leading-tight cursor-pointer flex-1 text-sm whitespace-no-wrap text-left truncate",
                        attrs: { title: _vm.activeClient.name },
                      },
                      [_vm._v(" " + _vm._s(_vm.activeClient.name) + " ")]
                    ),
                    _c("sun-arrow-down-svg", {
                      class: { "rotate-180": _vm.isOpen },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm.isOpen
            ? _c("div", {
                staticClass: "fixed inset-0",
                attrs: { tabindex: "-1" },
                on: { click: _vm.close },
              })
            : _vm._e(),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.isOpen
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mt-1 absolute right-0 text-left text-gray-400 mr-auto z-20 w-full client-dropdown",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray-800 rounded-md rounded-l-none shadow-lg z-20",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative py-3 mx-2" },
                          [
                            _c("sun-input", {
                              staticClass: "text-white",
                              attrs: {
                                id: "search-client",
                                "class-input":
                                  "bg-gray-800 text-white text-sm border-gray-500",
                                placeholder: "Search...",
                              },
                              on: { input: _vm.debounceSearch },
                            }),
                            _vm.isLoadingSearch
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "absolute t-20 right-0 mr-3 flex items-center w-4 h-6 text-gray-600",
                                  },
                                  [
                                    _c("sun-loading", {
                                      staticClass: "h-4 text-orange-400",
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "absolute t-20 right-0 mr-3 flex items-center w-4 h-6 text-gray-600",
                                  },
                                  [_c("search-svg")],
                                  1
                                ),
                          ],
                          1
                        ),
                        null !== _vm.activeClient
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "border-t-2 border-gray-500 rounded py-1",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex justify-start px-2 py-2",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "leading-none text-white font-black text-xs",
                                      },
                                      [_vm._v("Active client")]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-between items-center w-full hover:bg-gray-700 hover:text-white",
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "block pl-4 pr-2 py-3 text-sm leading-tight cursor-pointer flex-1 whitespace-no-wrap truncate",
                                        attrs: {
                                          title: _vm.activeClient.name,
                                          to: _vm.getDestination(),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.activeClient.name) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mr-2 cursor-pointer rounded-full",
                                      },
                                      [
                                        _c(
                                          "sun-badge",
                                          {
                                            staticClass:
                                              "text-xs context-badge",
                                            attrs: {
                                              color: "orange",
                                              title:
                                                _vm.contexts[
                                                  _vm.activeClient.type
                                                ].name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.contextAcronyms[
                                                    _vm.contexts[
                                                      _vm.activeClient.type
                                                    ].name
                                                  ]
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "border-t-2 border-gray-500 py-1" },
                          [
                            _vm.canGoToContextBoard
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-between px-2 py-2",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-xs leading-none text-white font-black",
                                      },
                                      [_vm._v("Clients")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-sm text-gray-400 leading-none hover:underline cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.goToViewAll()
                                          },
                                        },
                                      },
                                      [_vm._v(" View all ")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.clients.length > 0 &&
                            _vm.loadingClientName === null &&
                            !_vm.isLoadingSearch
                              ? _c(
                                  "div",
                                  { staticClass: "max-h-225 overflow-auto" },
                                  _vm._l(
                                    _vm.clientsWithoutActive,
                                    function (client, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "flex justify-between items-center w-full hover:bg-gray-700 hover:text-whire cursor-pointer",
                                          class: {
                                            "bg-gray-300":
                                              index ===
                                              _vm.arrowNavigationIndex,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.selectClient(client)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "block pl-4 pr-2 py-3 leading-tight cursor-pointer flex-1 text-sm whitespace-no-wrap truncate",
                                              attrs: { title: client.name },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(client.name) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "mr-2 cursor-pointer rounded-full",
                                            },
                                            [
                                              _c(
                                                "sun-badge",
                                                {
                                                  staticClass:
                                                    "text-2xs context-badge",
                                                  attrs: {
                                                    color: "orange",
                                                    title:
                                                      _vm.contexts[client.type]
                                                        .name,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.contextAcronyms[
                                                          _vm.contexts[
                                                            client.type
                                                          ].name
                                                        ]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm.loadingClientName
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full flex flex-col justify-center items-center p-6",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-bold text-gray-700 mb-4",
                                      },
                                      [
                                        _vm._v(
                                          "Loading " +
                                            _vm._s(_vm.loadingClientName) +
                                            " ..."
                                        ),
                                      ]
                                    ),
                                    _c("sun-loading", {
                                      staticClass: "w-6 text-orange-500",
                                    }),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "py-2 px-4 italic" }, [
                                  _vm.isLoadingSearch
                                    ? _c(
                                        "div",
                                        _vm._l(5, function (index) {
                                          return _c("client-loader", {
                                            key: `clientLoad_${index}`,
                                          })
                                        }),
                                        1
                                      )
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-sm text-center flex flex-col",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "p-2 my-2 m-auto w-40",
                                            },
                                            [_c("empty-search")],
                                            1
                                          ),
                                          _vm._v(
                                            " We couldn't find any matches "
                                          ),
                                        ]
                                      ),
                                ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm.isOpen
            ? _c("div", {
                staticClass: "fixed inset-0 z-10",
                attrs: { tabindex: "-1" },
                on: { click: _vm.close },
              })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }