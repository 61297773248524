<template>
  <div id="client-selector" class="text-left w-full">
    <div
      class="relative"
      @keydown.escape="close"
      @keydown.down.stop.prevent="onArrowDown"
      @keydown.up.stop.prevent="onArrowUp"
      @keydown.enter.stop.prevent="onEnter"
    >
      <div class="rounded-md hover:shadow-sm">
        <button
          type="button"
          class="inline-flex justify-between rounded-md rounded-l-none items-center w-full px-2 py-2 h-10 text-sm leading-5 font-medium text-white bg-gray-800 font-bold focus:outline-none focus:shadow-outline-gray transition ease-in-out duration-150"
          @click="onButtonClick"
        >
          <span class="flex justify-between items-center w-full cursor-pointer">
            <span
              class="block leading-tight cursor-pointer flex-1 text-sm whitespace-no-wrap text-left truncate"
              :title="activeClient.name"
            >
              {{ activeClient.name }}
            </span>
            <sun-arrow-down-svg :class="{ 'rotate-180': isOpen }" />
          </span>
        </button>
      </div>
      <div v-if="isOpen" class="fixed inset-0" tabindex="-1" @click="close" />
      <transition name="fade">
        <div v-if="isOpen" class="mt-1 absolute right-0 text-left text-gray-400 mr-auto z-20 w-full client-dropdown">
          <div class="bg-gray-800 rounded-md rounded-l-none shadow-lg z-20">
            <div class="relative py-3 mx-2">
              <sun-input
                id="search-client"
                class="text-white"
                class-input="bg-gray-800 text-white text-sm border-gray-500"
                placeholder="Search..."
                @input="debounceSearch"
              />
              <div v-if="isLoadingSearch" class="absolute t-20 right-0 mr-3 flex items-center w-4 h-6 text-gray-600">
                <sun-loading class="h-4 text-orange-400" />
              </div>
              <div v-else class="absolute t-20 right-0 mr-3 flex items-center w-4 h-6 text-gray-600">
                <search-svg />
              </div>
            </div>

            <div v-if="null !== activeClient" class="border-t-2 border-gray-500 rounded py-1">
              <div class="flex justify-start px-2 py-2">
                <span class="leading-none text-white font-black text-xs">Active client</span>
              </div>
              <div class="flex justify-between items-center w-full hover:bg-gray-700 hover:text-white">
                <router-link
                  class="block pl-4 pr-2 py-3 text-sm leading-tight cursor-pointer flex-1 whitespace-no-wrap truncate"
                  :title="activeClient.name"
                  :to="getDestination()"
                >
                  {{ activeClient.name }}
                </router-link>
                <div class="mr-2 cursor-pointer rounded-full">
                  <sun-badge color="orange" class="text-xs context-badge" :title="contexts[activeClient.type].name">
                    {{ contextAcronyms[contexts[activeClient.type].name] }}
                  </sun-badge>
                </div>
              </div>
            </div>
            <div class="border-t-2 border-gray-500 py-1">
              <div v-if="canGoToContextBoard" class="flex justify-between px-2 py-2">
                <span class="text-xs leading-none text-white font-black">Clients</span>
                <span class="text-sm text-gray-400 leading-none hover:underline cursor-pointer" @click="goToViewAll()">
                  View all
                </span>
              </div>
              <div
                v-if="clients.length > 0 && loadingClientName === null && !isLoadingSearch"
                class="max-h-225 overflow-auto"
              >
                <div
                  v-for="(client, index) in clientsWithoutActive"
                  :key="index"
                  class="flex justify-between items-center w-full hover:bg-gray-700 hover:text-whire cursor-pointer"
                  :class="{ 'bg-gray-300': index === arrowNavigationIndex }"
                  @click="selectClient(client)"
                >
                  <span
                    class="block pl-4 pr-2 py-3 leading-tight cursor-pointer flex-1 text-sm whitespace-no-wrap truncate"
                    :title="client.name"
                  >
                    {{ client.name }}
                  </span>
                  <div class="mr-2 cursor-pointer rounded-full">
                    <sun-badge color="orange" class="text-2xs context-badge" :title="contexts[client.type].name">
                      {{ contextAcronyms[contexts[client.type].name] }}
                    </sun-badge>
                  </div>
                </div>
              </div>
              <div v-else-if="loadingClientName" class="w-full flex flex-col justify-center items-center p-6">
                <div class="font-bold text-gray-700 mb-4">Loading {{ loadingClientName }} ...</div>
                <sun-loading class="w-6 text-orange-500" />
              </div>
              <div v-else class="py-2 px-4 italic">
                <div v-if="isLoadingSearch">
                  <client-loader v-for="index in 5" :key="`clientLoad_${index}`" />
                </div>

                <p v-else class="text-sm text-center flex flex-col">
                  <span class="p-2 my-2 m-auto w-40">
                    <empty-search />
                  </span>
                  We couldn't find any matches
                </p>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div v-if="isOpen" class="fixed inset-0 z-10" tabindex="-1" @click="close" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { ACTIVE_CLIENT, SET_ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { getClientById as getClientByIdSocialAudience } from '@/services/modules/socialAudience/client';
import { getClientById as getClientByIdThirdParty } from '@/services/modules/ThirdParty/client';
import ClientLoader from '@/components/atoms/Loaders/ClientLoader';
import { getClients } from '@/services/modules/Core/client';
import { getClients as getEcommerceClients } from '@/services/modules/ecommerce/client';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';
import { ROUTE_COMPONENTS } from '@/model/shared/router';
import EmptySearch from '@/components/icons/EmptySearch';
import { USER } from '@/store/modules/auth/keys';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { ROLES } from '@/model/shared/roles';
import getDefaultRouterNameByUser from '@/model/shared/context/getDefaultRouterByUser';

export default {
  name: 'ClientSelector',
  components: {
    EmptySearch,
    SearchSvg: () => import('../../icons/SearchSvg'),
    ClientLoader,
  },
  props: {
    canGoToContextBoard: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      clients: [],
      isLoadingSearch: false,
      isOpen: false,
      hasError: false,
      loadingClientName: null,
      contexts: CONTEXTS,
      arrowNavigationIndex: -1,
    };
  },
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      activeContext: ACTIVE_CONTEXT,
      user: USER,
    }),
    homeComponent() {
      return getDefaultRouterNameByUser(this.activeContext, this.user);
    },
    clientsWithoutActive() {
      return this.clients.filter(client => client.id !== this.activeClient.id);
    },
    contextAcronyms() {
      const contexts = {};
      Object.values(this.contexts).forEach(context => {
        contexts[`${context.name}`] = this.contextAcronym(context.name);
      });
      return contexts;
    },
  },
  methods: {
    ...mapActions([SET_ACTIVE_CLIENT]),
    getDestination() {
      let targetComponent = this.homeComponent;

      if (this.$router.currentRoute.name.indexOf(this.activeContext.baseUrl) > -1) {
        targetComponent = this.$router.currentRoute.name;
      }

      return {
        name: targetComponent,
        params: {
          clientId: this.activeClient.id,
        },
      };
    },
    async getClients() {
      this.arrowNavigationIndex = -1;
      this.isLoadingSearch = true;
      const params = new QueryParamsBuilder(1, 10);
      params.addInclude('name');
      try {
        const { data } = await getClients(params);
        this.clients = data;
        this.isLoadingSearch = false;
      } catch (error) {
        this.isLoadingSearch = error.code !== HTTPStatusCode.Cancel;
      }
    },
    debounceSearch: debounce(function (value) {
      this.searchClients(value);
    }, 500),
    searchClients(value) {
      if (value.length >= 3) this.getClientsBySearch(value);
      else if (value.length === 0) this.clients = [] && this.getClients();
    },
    async getClientsBySearch(value) {
      this.arrowNavigationIndex = -1;
      this.isLoadingSearch = true;
      const params = new QueryParamsBuilder(1, 10);
      params.addInclude('name').addQuery(value);
      try {
        const { data } = await getClients(params.buildQuery());
        this.clients = data;
      } catch (error) {
        this.hasError = error.code !== HTTPStatusCode.Cancel;
      } finally {
        this.isLoadingSearch = false;
      }
    },
    async getPublisherClients() {
      this.arrowNavigationIndex = -1;
      this.isLoadingSearch = true;
      const params = new QueryParamsBuilder();
      params.addInclude('campaignCount');
      try {
        const { data } = await getEcommerceClients(params);
        this.clients = data;
      } catch (error) {
        this.hasError = error.code !== HTTPStatusCode.Cancel;
      } finally {
        this.isLoadingSearch = false;
      }
    },
    async selectClient(client) {
      this.loadingClientName = client.name;
      try {
        this.isOpen = false;

        const clientComplete = await this.getCompleteClient(client);
        this[SET_ACTIVE_CLIENT](clientComplete);

        await this.$router.push(this.getDestination());
      } catch (error) {
        this.hasError = error.code !== HTTPStatusCode.Cancel;
      } finally {
        this.loadingClientName = null;
      }
    },
    async getCompleteClient(client) {
      let completeClient = null;
      if (client.type === CONTEXTS.SOCIAL_AUDIENCE.id) {
        const { data } = await getClientByIdSocialAudience(client.id);
        completeClient = data;
      } else if (client.type === CONTEXTS.THIRD_PARTY.id) {
        const { data } = await getClientByIdThirdParty(client.id);
        completeClient = data;
      }
      return completeClient !== null ? completeClient : client;
    },
    deleteClient() {
      this[SET_ACTIVE_CLIENT](null);
    },
    goToViewAll() {
      this.deleteClient();
      this.$router.push({ name: `${CONTEXT_BOARD_BASE}-${ROUTE_COMPONENTS.CLIENT}` });
    },
    onButtonClick() {
      this.isOpen = !this.isOpen;
      this.arrowNavigationIndex = -1;

      if (
        this.activeContext.id === CONTEXTS.ECOMMERCE.id &&
        this.user?.contextRoles.find(cr => cr.context === CONTEXTS.ECOMMERCE.id && cr.role === ROLES.PUBLISHER.id)
      ) {
        this.getPublisherClients();
      } else {
        this.getClientsBySearch();
      }
    },
    onArrowDown() {
      this.arrowNavigationIndex = (this.arrowNavigationIndex + 1) % this.clients.length;
    },
    onArrowUp() {
      this.arrowNavigationIndex =
        this.arrowNavigationIndex <= 0 // if index is in the first item or it is not set
          ? this.clients.length - 1
          : (this.arrowNavigationIndex - 1) % this.clients.length;
    },
    onEnter() {
      const client = this.clients[this.arrowNavigationIndex];
      this.selectClient(client);
      this.arrowNavigationIndex = -1;
    },
    close() {
      this.isOpen = false;
    },
    contextAcronym(context) {
      return context
        .split(' ')
        .map(a => a[0])
        .join('');
    },
  },
};
</script>
<style scoped>
.t-20 {
  top: 20px;
}

::v-deep #search-client {
  @apply text-white;
}

::v-deep .context-badge .opacity-50 {
  @apply opacity-100;
}

.has-home-button .client-dropdown {
  width: 125%;
}
.max-h-225 {
  max-height: 225px;
}
</style>
